import React from "react";
import Unity, {UnityContext} from "react-unity-webgl";
import {
    getJsonByIndex
} from "./util/interact.js";
import {
    contractABI,
    web3,
    getContractData,
    contractAddress
} from "./util/contract-controller-svg.js";
import MintButton from './util/MintButton'
import {Button, Col, Modal, ProgressBar, Row, Spinner} from "react-bootstrap";


class CollectionUnitySVG extends React.Component {

    constructor(props) {
        super(props);
        this.projectName = props.name;
        this.collectionIndex = props.collectionIndex;

        this.unityContext = new UnityContext({
            dataUrl: "../Projects/" + this.projectName + "/Build/" + this.projectName + ".data.unityweb",
            frameworkUrl: "../Projects/" + this.projectName + "/Build/" + this.projectName + ".framework.js.unityweb",
            loaderUrl: "../Projects/" + this.projectName + "/Build/" + this.projectName + ".loader.js",
            codeUrl: "../Projects/" + this.projectName + "/Build/" + this.projectName + ".wasm.unityweb",
        });
        this.unityContext.on("progress", (progression) => {
            this.setState({
                progression: progression,
            });
        });

        this.unityContext.on("canvas", function (canvas) {
            canvas.width = 1000;
            canvas.height = 1000;
        });

        this.state = {
            showModal: false,
            mintResponse: "",
            mintSuccess: false,
            newTokenId: 0,
            progression: 0,
            tokenIndex: 0,
            collectionAmount: 100,
            nftDescription: "",
            saleIsActive: false,
            collectionProgressPercent: 100,
            collectionStatus: "Done",
            collectionData: [],
            mintPrice: 0,
            maxTokens: 0,
            mintButtonActive: true,
            contractData: [],
        };

        this.mint = this.mint.bind(this);
        this.mintCallback = this.mintCallback.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.gotoToken = this.gotoToken.bind(this);
        this.unitySubmitData = this.unitySubmitData.bind(this);
        this.unityStatusCallback = this.unityStatusCallback.bind(this);
    }

    async componentDidMount() {
        this.unityContext.on("UnitySubmitData", this.unitySubmitData);
        this.unityContext.on("UnityGetStatus", this.unityStatusCallback);
        var collectionJson = await getJsonByIndex(this.collectionIndex);

        this.setState({
            nftDescription: collectionJson.nftDescription,
            collectionStatus: collectionJson.status,
            collectionData: collectionJson
        });
        var contractDataObject = await getContractData();
        this.setState({
            contractData: contractDataObject
        })
    }


    async unitySubmitData(colors, gridX, gridY) {
        console.log(colors.length);
        if (this.isAllDataNull(colors)) {
            this.setState({
                mintResponse: "data error... all colors seems to be 0",
                mintSuccess: false,
            });
            return;
        }
        if (this.isAllDataNull(gridX)) {
            this.setState({
                mintResponse: "data error... all gridX seems to be 0",
                mintSuccess: false,
            });
            return;
        }
        if (this.isAllDataNull(gridY)) {
            this.setState({
                mintResponse: "data error... all gridY seems to be 0",
                mintSuccess: false,
            });
            return;
        }


        if (colors.length !== 128) {
            console.log("error, wrong color count")
            this.setState({
                mintResponse: "data error... wrong colors count",
                mintSuccess: false,
            });
            return;
        }
        if (gridX.length !== 144) {
            this.setState({
                mintResponse: "data error... wrong gridX count",
                mintSuccess: false,
            });
            return;
        }
        if (gridY.length !== 144) {
            this.setState({
                mintResponse: "data error... wrong gridY count",
                mintSuccess: false,
            });
            return;
        }
        console.log("color palette index:  " + colors[122]);

        const response = await this.mintNFT(colors, gridX, gridY, this.mintCallback);
        if (response.mintStarted) {
            this.setState({
                mintResponse: "sending to blockchain...",
                mintSuccess: false,
                mintButtonActive: false
            }, () => {
                console.log(this.state.mintButtonActive)
            });
        }
    }

    isAllDataNull(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i] !== 0) return false;
        }
        return true;
    }

    async unityStatusCallback() {
        this.setState({mintButtonActive: true})
    }

   

    async mintCallback(tokenId) {
        console.log("minted new token with id " + tokenId);
        this.setState({
            mintResponse: "✅ token minted",
            mintSuccess: true,
            newTokenId: tokenId
        })
    }

    async mint(event) {
        this.setState({
            showModal: true,
            mintResponse: "Generating nft data...",
            mintSuccess: false,
            mintButtonActive: false
        });
        this.unityContext.send("CompositionGenerative", "CaptureNFT");
    }

    async mintNFT(colors, gridX, gridY, mintCallback) {

        window.contract = await new web3.eth.Contract(contractABI, contractAddress);

        window.contract.events.newTokenMinted({
            filter: {},
            fromBlock: "latest"
        }, function (error, event) {
            mintCallback(event.returnValues[0]);
        })

        const transactionParameters = {
            to: contractAddress, // Required except during contract publications.
            from: window.ethereum.selectedAddress, // must match user's active address.
            value: parseInt(this.state.contractData.priceInWei).toString(16),
            data: window.contract.methods.mintNFT(window.ethereum.selectedAddress,
                colors,
                gridX,
                gridY).encodeABI(),
        };

        try {
            await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [transactionParameters],
            });


            return {
                mintStarted: true,
                //status: "✅ Check out your transaction on Etherscan: https://rinkeby.etherscan.io/tx/" + txHash,
            };


        } catch (error) {
            return {
                mintStarted: false,
                //status: "😥 Something went wrong: " + error.message,
            };
        }

    }


    handleClose() {
        this.setState({showModal: false});
    }

    gotoToken() {
        window.location.href = "/view-token-svg/" + this.state.newTokenId;
    }

    render() {
        const mintSuccess = this.state.mintSuccess;
        const collectionInPreview = this.state.collectionData.status === "Preview";
        const mintButtonActive = this.state.mintButtonActive;
        let footer;
        let header;

        if (mintSuccess) {
            footer = <Modal.Footer>
                <Button variant="primary" onClick={this.gotoToken}>Take me to it!</Button>
            </Modal.Footer>;
            header = <Modal.Title>mint done!</Modal.Title>;
        } else {
            footer = <Modal.Footer></Modal.Footer>;
            header = <Modal.Title><Spinner animation="border" size="sm"/> getting minted...</Modal.Title>;
        }

        const now = this.state.progression * 100;
        let progressInstance = <ProgressBar animated now={now} label={`${now}%`}/>;
        let loadingText = <p><Spinner animation="border" size="sm"/> loading collection</p>;
        let button;
        if (now === 100) {
            loadingText = "";
            progressInstance = "";

            if (collectionInPreview) {
                button = <Button variant="secondary" className="mint-button-inactive">collection in preview</Button>
            }
            else if (mintButtonActive) {
                button = <MintButton onMint={this.mint}/>
            }
            else {
                button = <Button variant="secondary" className="mint-button-inactive">composition is minted</Button>
            }
        }

        return (
            <div>
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        {header}
                    </Modal.Header>
                    <Modal.Body>
                        {String(this.state.mintResponse)}
                    </Modal.Body>
                    {footer}
                </Modal>
                {loadingText}
                {progressInstance}
                <Unity
                    unityContext={this.unityContext}
                    matchWebGLToCanvasSize={false}

                    style={{
                        width: "100%",
                        height: "auto",
                        background: "white",
                    }}
                />
                <br/>
                <Row className="justify-content-md-center">
                    <Col xs lg="1"></Col>
                    <Col md="auto">
                        {button}
                    </Col>
                    <Col xs lg="1"></Col>
                </Row>
            </div>
        );
    }

}

export default CollectionUnitySVG;