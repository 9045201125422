import React from "react";
import {
    connectWallet,
    getCurrentWalletConnected,
} from "./util/interact.js";
import Button from 'react-bootstrap/Button';
import {Col} from "react-bootstrap";

require("dotenv").config();


class WalletConnector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            walletAddress: "",
            status: "",
            walletAccount: ""
        }
        this.connectWalletPressed = this.connectWalletPressed.bind(this);
    }

    async componentDidMount() {
        const {address, status} = await getCurrentWalletConnected();
        this.setWallet(address);
        this.setStatus(status);
        this.addWalletListener();
    }


    setStatus(statusString) {
        this.setState({status: statusString});
    }

    setWallet(walletString) {
        this.setState({walletAddress: walletString});
    }

    addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    this.setWallet(accounts[0]);
                    this.setStatus("👆🏽 Write a message in the text-field above.");
                } else {
                    this.setWallet("");
                    this.setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
        } else {
            this.setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }

    async connectWalletPressed() {
        console.log("pressed button")
        const walletResponse = await connectWallet();
        this.setStatus(walletResponse.status);
        this.setWallet(walletResponse.address);
    };

    render() {
        return (
            <div>
                <Col xs lg="12">
                    <Button variant="outline-primary" id="walletButton" onClick={this.connectWalletPressed}>
                        {this.state.walletAddress.length > 0 ? (
                            "Connected: " +
                            String(this.state.walletAddress).substring(0, 6) +
                            "..." +
                            String(this.state.walletAddress).substring(38)
                        ) : (
                            <span>Connect Wallet</span>
                        )}
                    </Button>
                </Col>
            </div>
        );
    };
}

export default WalletConnector;
