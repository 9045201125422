import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {getToken, contractAddress} from './util/interact'


class ViewToken extends React.Component {
    constructor(props) {
        super(props);
        
        //this.tokenId = this.props.match.params.id;
        this.tokenId = props.id || this.props.match.params.id
        
        this.state = {
            name: "...",
            description: "...",
            image: "...",
            properties: []
        }
        this.gotoOpensea = this.gotoOpensea.bind(this);

    }

    async componentDidMount() {
        var token = await getToken(this.tokenId);
        await fetch(token)
            .then((response) => {
                return response.json()
            })
            .then((metadata) => {
                this.setState({
                    name: metadata.name,
                    description: metadata.description,
                    image: metadata.image,
                    properties: metadata.properties
                },() => {
                    console.log(metadata.attributes)
                })
            }).catch(function () {
                console.log("error");
            });

    }

    gotoOpensea() {
        window.location.href = "https://opensea.io/assets/" + contractAddress + "/" + this.tokenId;
    }

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <img width="100%" alt="" src={this.state.image}/>
                            <p>
                                <h2>{String(this.state.name)}</h2>
                            </p>
                            <p>
                                <span className="text-muted">{String(this.state.description)}</span>
                            </p>
                            <Button onClick={this.gotoOpensea}>
                                view on opensea
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ViewToken;