import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import WalletConnector from "./WalletConnector";
import CollectionEdit from './CollectionEdit'
import CollectionOverview from './CollectionOverview'
import CreateCollection from './CreateCollection'
import Home from './Home'
import About from './About'
import ViewToken from './ViewToken'
import Footer from './Footer'
import CollectionsList from './CollectionsList'
import ViewTokenSVG from './ViewTokenSVG'

import {Container, Row, Navbar, Nav} from 'react-bootstrap';

function App() {
    return (
        <div className="App">'
            <Container>
                <Router>
                    <Row>
                        <Navbar expand="lg" variant="light" >
                            <Container>
                               
                                
                                <Navbar.Brand href="/">scramble</Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav"/>

                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link href="/collections">collections</Nav.Link>
                                        <Nav.Link href="/about">about</Nav.Link>
                                    </Nav>
                                    <Nav>
                                        <WalletConnector/>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>

                    </Row>
                    <Row>
                        <Switch>

                            <Route path="/view-token/:id" component={ViewToken}/>

                            <Route path="/create-collection"> <CreateCollection/></Route>
                            <Route path="/Collections/:uri" component={CollectionOverview}/>
                            <Route path="/edit-collection/:uri" component={CollectionEdit}/>
                            <Route path="/collections/" component={CollectionsList}/>

                            <Route path="/about"> <About/></Route>
                            <Route path="/view-token-svg/:id" component={ViewTokenSVG} /> 
                            <Route path="/"> <Home/></Route>

                        </Switch>

                    </Row>
                    <Row>
                        <Footer/>
                    </Row>
                </Router>

            </Container>
        </div>
    )
}

export default App;
