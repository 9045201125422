import React from 'react';
import {
    mintNFT,
    getCollection,
    getChainIndexFromURI,
    getCreatedTokensList,
    getMintedTokens
} from "./util/interact.js";
import {Button, Modal, Spinner} from "react-bootstrap";
import MintButton from './util/MintButton'


class CollectionRendered extends React.Component {

    constructor(props) {
        super(props);
        this.uri = props.uri;

        this.state = {
            name: "...",
            description: "...",
            image: "...",
            currentTokenIndex: 0,
            collectionIndex: -1,
            showModal: false,
            mintResponse: "",
            mintSuccess: false,
            newTokenId: 0,
            ipfsHash: "",
            mintedTokens: []
        };

        this.mint = this.mint.bind(this);
        this.mintCallback = this.mintCallback.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.gotoToken = this.gotoToken.bind(this);
        this.loadToken = this.loadToken.bind(this);
        this.loadRandom = this.loadRandom.bind(this);
    }


    async componentDidMount() {
        getCreatedTokensList((response) => {
            console.log(response);
        })
        var index = await getChainIndexFromURI(this.uri)
        this.setState({collectionIndex: index});

        const collectionData = await getCollection(index);
        this.setState({
            currentTokenIndex: collectionData.tokenIndex,
            ipfsHash: collectionData.ipfs
        }, () => {
            this.loadToken(this.state.currentTokenIndex);
        });
        var mintedTokens = await getMintedTokens(index)
        this.setState({mintedTokens: mintedTokens}, ()=> {
            console.log(this.state.mintedTokens)
        })
    }


    async loadToken(tokenIndex) {
        this.setState({currentTokenIndex: tokenIndex});
        await fetch("https://gateway.pinata.cloud/ipfs/" + this.state.ipfsHash + "/" + tokenIndex+".json")
            .then((response) => {
                return response.json()
            })
            .then((metadata) => {
                this.setState({
                    image: metadata.image,
                    name: metadata.name,
                    description: metadata.description
                })
            }).catch(function () {
                console.log("error");
            });
    }


    async mint() {
        var tokenIndex = this.state.currentTokenIndex;
        if (tokenIndex === undefined) {
            console.log("index is undefined");
            tokenIndex = 0;
        }
        const response = await mintNFT("", this.state.collectionIndex, tokenIndex, this.mintCallback);
        if (response.mintStarted) {
            this.setState({
                showModal: true,
                mintResponse: "Minting in progress, this will take some time...",
                mintSuccess: false
            });
        }

    }

    async mintCallback(tokenId) {
        console.log("minted new token with id " + tokenId);
        this.setState({
            mintResponse: "✅ token minted",
            mintSuccess: true,
            newTokenId: tokenId
        })
    }

    handleClose() {
        this.setState({showModal: false});
    }

    gotoToken() {
        window.location.href = "/view-token/" + this.state.newTokenId;
    }

    async loadRandom() {
        var newIndex = Math.floor(Math.random() * 10);
        while (newIndex === this.state.currentTokenIndex || this.state.mintedTokens.includes(newIndex.toString())) {
            newIndex = Math.floor(Math.random() * 10);
        }
        this.loadToken(newIndex);
    }

    render() {
        const mintSuccess = this.state.mintSuccess;
        let footer;

        if (mintSuccess) {
            footer = <Modal.Footer>
                <Button variant="primary" onClick={this.gotoToken}>Take me to it!</Button>
            </Modal.Footer>;
        } else {
            footer = <Modal.Footer></Modal.Footer>;
        }

        return (
            <div>
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title><Spinner animation="border"/> getting minted</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {String(this.state.mintResponse)}
                    </Modal.Body>
                    {footer}
                </Modal>

                <img width="100%" alt="" src={this.state.image} onClick={this.loadRandom}/>
                <br/>
                <p>
                    {this.state.name}<br/>
                    <small>{this.state.description}</small>
                </p>
                <br/><br/>
                <MintButton onMint={this.mint}/>
            </div>
        );
    }
}

export default CollectionRendered;
