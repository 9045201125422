import React from "react";
import CollectionInfo from "./CollectionInfo";

import {
    mintNFT
} from "./util/interact.js";
import CollectionRendered from './CollectionRendered'
import CollectionUnity from './CollectionUnity'
import CollectionUnitySVG from './CollectionUnitySVG'

import CollectionTokenGallery from './CollectionTokenGallery'
import CollectionSVGGallery from './CollectionSVGGallery'
import {Container, Row, Col} from 'react-bootstrap';


class CollectionOverview extends React.Component {

    constructor(props) {
        super(props);
        this.projectName = props.name;
        this.collectionURI = this.props.match.params.uri;
        this.mint = this.mint.bind(this);

        this.state = {
            collection: {
                collectionIndex: -1,
                collectionName: "...",
                author: "...",
                description: "...",
                projectType: "Unity",
                projectDirectory: "",
                collectionStatus: "",
                previewImage: "",
                status: ""
            }
        };
    }

    async componentDidMount() {
        var data = this.getByURI(this.collectionURI);

        var newCollectionData = {
            collectionIndex: data.index,
            collectionName: data.name,
            author: data.author,
            description: data.description,
            projectType: data.projectType,
            projectDirectory: data.projectDirectory,
            collectionStatus: data.status,
            previewImage: data.previewImage,
            status: data.status
        };
        console.log("preview " + data.previewImage)


        this.setState((prevState) => ({
            collection: newCollectionData
        }), () => {
            console.log("state collection " + this.state.collection.collectionName)
        });
    }

    getByURI = (name) => {
        var jsonData = require('./json/CollectionsList.json');
        return jsonData.collections.find(data => data.uri === name);
    };


    async mint(event) {
        //const {success, status} = 
        await mintNFT("", this.collectionIndex);
    }

    render() {
        let preview;
        let mintGallery;
        let mintGalleryHeader;
        if (this.state.collection.collectionIndex !== -1) {
            if (this.state.collection.status === "Upcoming") {
                preview = <img width="100%" src={this.state.collection.previewImage}
                               alt={this.state.collection.previewImage}/>;
            } else if (this.state.collection.projectType === "Unity") {
                preview = <CollectionUnity collectionIndex={this.state.collection.collectionIndex}
                                           name={this.state.collection.projectDirectory}/>;
            } else if (this.state.collection.projectType === "svgmint") {
                preview = <CollectionUnitySVG collectionIndex={this.state.collection.collectionIndex}
                                              name={this.state.collection.projectDirectory}/>;
            } else {
                preview = <CollectionRendered uri={this.collectionURI}/>;
            }
            if (this.state.collection.status === "Active" || this.state.collection.status === "Hidden") {
                if (this.state.collection.projectType === "svgmint") {
                    mintGalleryHeader = <div className="section-header"><span>Previous mints</span></div>;
                    mintGallery = <CollectionSVGGallery/>;
                } else {
                    mintGalleryHeader = <div className="section-header"><span>Previous mints</span></div>;
                    mintGallery = <CollectionTokenGallery collectionIndex={this.state.collection.collectionIndex}/>;
                }
            }
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col md={4}>
                            <CollectionInfo uri={this.collectionURI}/>
                        </Col>
                        <Col>
                            {preview}
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>

                    <Row>
                        {mintGalleryHeader}
                        {mintGallery}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CollectionOverview;