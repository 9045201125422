import React from 'react';
import {
    getCollection,
    getJsonByURI
} from "./util/interact.js";
import {
    getContractData
} from "./util/contract-controller-svg.js";
import {Badge, ProgressBar} from "react-bootstrap";

const Web3 = require('web3');
const web3 = new Web3('https://eth-rinkeby.alchemyapi.io/v2/QMxhK-7GBjBzCKfux2ZqMxhHCj69C-Tw');


class CollectionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.uri = props.uri;

        this.state = {
            collectionIndex: "0",
            collectionName: "...",
            author: "...",
            description: "...",
            tokenCount: "0/0",
            mintPrice: "0",
            tokenIndex: 0,
            tokensAmount: 0,
            collectionStatus: "",
            projectType: ""
        };
    }


    async componentDidMount() {
        var data = await getJsonByURI(this.uri);

        this.setState({
            collectionName: data.name,
            author: data.author,
            description: data.description,
            collectionIndex: data.index,
            collectionStatus: data.status,
            projectType: data.projectType,
            saleIsActive: ""
        }, () => {
            this.getChainData()
        });


    }

    async getChainData() {
        var collectionObject = await getCollection(this.state.collectionIndex);

        if (this.state.projectType === "svgmint") {
            collectionObject = await getContractData();
        }
        if (collectionObject != null) {
            this.setState({
                mintPrice: collectionObject.priceInWei,
                tokenIndex: collectionObject.tokenIndex,
                tokensAmount: collectionObject.numberOfTokens,
                tokenCount: collectionObject.tokenIndex + "/" + collectionObject.numberOfTokens,
                saleIsActive: collectionObject.isSaleActive,
            }, () => {
                //x var mintPercentage = (this.state.tokenIndex / this.state.tokensAmount) * 100;
            });
        }
    }


    render() {
        var mintPercentage = (this.state.tokenIndex / (this.state.tokensAmount)) * 100;
        var label = mintPercentage + " %";
        let progress;
        let statusText = "active";
        if (!this.state.saleIsActive)
            statusText = "paused";

        let mintPrice = String(web3.utils.fromWei(String(this.state.mintPrice), "ether")) + " ETH";
        if(mintPrice === "0 ETH") {
            mintPrice = "FREE";
        }
        if (this.state.collectionStatus === "Active" || this.state.collectionStatus === "Hidden") {
            progress = <p>
                <ProgressBar variant="primary" style={{minWidth: '20em'}} now={mintPercentage} label={label}>
                </ProgressBar>
                <small style={{width: '200px'}}>Mint
                    progress:</small><Badge bg="info">{this.state.tokenIndex}/{this.state.tokensAmount}</Badge>
                <br/>


                <small>Minting price:</small>
                <Badge bg="info">{mintPrice}</Badge>
                <br/>
                <small>Mint status:</small>
                <Badge bg="info">{statusText} </Badge>
            </p>;
        }
        return (
            <div>
                <h3> {String(this.state.collectionName)}</h3>
                <p>
                    By: {String(this.state.author)}
                </p>

                {progress}
                <p style={{whiteSpace: 'pre-line'}}>
                    <div dangerouslySetInnerHTML={ { __html: this.state.description } }></div>

                </p>
            </div>
        );
    }
}

export default CollectionInfo;
