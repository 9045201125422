import React from "react";
import {Container, Row} from "react-bootstrap";
import CollectionGallery from './CollectionGallery'

class CollectionsList extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <div className="section-header">
                            <span>all collections</span>
                        </div>
                        <CollectionGallery small={true} type={"all"} />
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CollectionsList;