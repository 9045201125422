import React from 'react';
import {Row, Card, Container} from "react-bootstrap";
import {getCollection, getToken} from './util/interact'

class CollectionTokenGallery extends React.Component {

    constructor(props) {
        super(props);
        this.collectionIndex = props.collectionIndex;

        this.state = {
            tokens: []
        }
    }

    async componentDidMount() {
        let collectionObject = await getCollection(this.collectionIndex);


        for (var i = 0; i < 12; i++) {
            var tokenIndex = (10000 * this.collectionIndex) + Math.floor(Math.random() * collectionObject.tokenIndex);
            
            await this.fetchAndSetToken(tokenIndex);
        }
    }
    
    async fetchAndSetToken(tokenIndex) {
        console.log("fetching " + tokenIndex)
        var token = await getToken(tokenIndex);
        await fetch(token)
            .then((response) => {
                return response.json()
            })
            .then((metadata) => {
                this.setState({
                    tokens: [...this.state.tokens, {
                        name: metadata.name,
                        image: metadata.image,
                        tokenId: tokenIndex
                    },]
                });

            }).catch(function () {
                console.log("error");
            });
    }


    clickToken(index) {
        window.location.href = "/view-token/" + index;
    }


    render() {


        return (
            <div>
                <Container>
                    <Row>

                        {this.state.tokens.map(dataRow => {
                            return (

                                <Card style={{width: '11rem'}} key={dataRow.name}
                                      onClick={() => this.clickToken(dataRow.tokenId)}>
                                    <div className="card-img-wrap">
                                        <Card.Img variant="top" src={dataRow.image}/>
                                    </div>
                                    <Card.Body>
                                       
                                        <Card.Text><small>{dataRow.name}</small></Card.Text>
                                    </Card.Body>
                                </Card>

                            )
                        })}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CollectionTokenGallery;
