import React from 'react';
import Button from 'react-bootstrap/Button';
import {
    getCollection,
    updateCollection
} from "./util/interact.js";
import {Col, Container, Form, Row} from "react-bootstrap";

class CollectionEdit extends React.Component {
    constructor(props) {
        super(props);
        this.collectionURI = this.props.match.params.uri;

        this.state = {
            ipfs: '0x0',
            uri: '0x0',
            ownerAddress: '0x0',
            ownerCut: 100,
            numberOfTokens: 0,
            mintPrice: 0,
            isActive: false,
            collectionIndex: 1
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    async componentDidMount() {
        var data = this.getByURI(this.collectionURI);

        this.setState({
            collectionIndex: data.index
        }, () => {
            this.loadCollection()
        });
    }

    getByURI = (name) => {
        var jsonData = require('./json/CollectionsList.json');
        return jsonData.collections.find(data => data.uri === name);
    };

    async loadCollection() {
        console.log("loadCollection " + this.state.collectionIndex);
        const collectionData = await getCollection(this.state.collectionIndex);
        this.setState({
            ipfs: collectionData.ipfs,
            uri: collectionData.uri,
            ownerAddress: collectionData.owner,
            ownerCut: collectionData.ownerCut,
            numberOfTokens: collectionData.numberOfTokens,
            mintPrice: collectionData.priceInWei,
            isActive: collectionData.isSaleActive
        });

        console.log(collectionData.isSaleActive)
        this.setState({isActive: true})
        //this.setState({ checkboxChecked: !this.state.checkboxChecked });

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;
        console.log(name + " " + value)
        this.setState({
            [name]: value,
        });
    }

    handleSubmit(event) {
        this.handleEditCollection();
        event.preventDefault();
    }


    async handleEditCollection() {
        await updateCollection(
            this.state.collectionIndex,
            this.state.ipfs,
            this.state.numberOfTokens,
            this.state.ownerAddress,
            this.state.ownerCut,
            this.state.mintPrice,
            this.state.isActive,
        );
    }

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>

                            <h3>Edit Contract Metadata</h3>
                            <Form>
                                <Form.Group as={Row} className="sm-4">
                                    <Form.Label column sm={3}>Ipfs:</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="text" name="ipfs" onChange={this.handleInputChange}
                                                      placeholder={this.state.ipfs}/>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} className="sm-4">
                                    <Form.Label column sm={3}>Owner address:</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="text" name="ownerAddress" onChange={this.handleInputChange}
                                                      placeholder={this.state.ownerAddress}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="sm-4">
                                    <Form.Label column sm={3}>Owner cut:</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="text" name="ownerCut" onChange={this.handleInputChange}
                                                      placeholder={this.state.ownerCut}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="sm-4">
                                    <Form.Label column sm={3}>Number of tokens:</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="number" name="numberOfTokens"
                                                      onChange={this.handleInputChange}
                                                      placeholder={this.state.numberOfTokens}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="sm-4">
                                    <Form.Label column sm={3}>Mint price (in Wei):</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control type="number" name="mintPrice" onChange={this.handleInputChange}
                                                      placeholder={this.state.mintPrice}/>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} className="sm-4" controlId="formHorizontalCheck">
                                    <Col sm={{span: 8, offset: 3}}>
                                        <Form.Check checked={this.state.isActive} name="isActive"
                                                    onChange={this.handleInputChange} label="Sale active:"/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="sm-4">
                                    <Col sm={{span: 8, offset: 3}}>
                                        <Button className="mint-button"
                                                onClick={this.handleSubmit}>submit</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container></div>
        );
    }
}

export default CollectionEdit;