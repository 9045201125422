import React from "react";
import Unity, {UnityContext} from "react-unity-webgl";
import {
    mintNFT, getCollection, getJsonByIndex, getMintedTokens
} from "./util/interact.js";
import MintButton from './util/MintButton'
import {Badge, Button, Modal, ProgressBar, Spinner} from "react-bootstrap";
import ViewToken from "./ViewToken";


class CollectionUnity extends React.Component {

    constructor(props) {
        super(props);
        this.projectName = props.name;
        this.collectionIndex = props.collectionIndex;

        this.unityContext = new UnityContext({
            dataUrl: "../Projects/" + this.projectName + "/Build/" + this.projectName + ".data.unityweb",
            frameworkUrl: "../Projects/" + this.projectName + "/Build/" + this.projectName + ".framework.js.unityweb",
            loaderUrl: "../Projects/" + this.projectName + "/Build/" + this.projectName + ".loader.js",
            codeUrl: "../Projects/" + this.projectName + "/Build/" + this.projectName + ".wasm.unityweb",
        });
        this.unityContext.on("progress", (progression) => {
            this.setState({
                progression: progression,
            });
        });

        this.unityContext.on("canvas", function (canvas) {
            canvas.width = 4000;
            canvas.height = 4000;
        });

        this.state = {
            showModal: false,
            mintResponse: "",
            mintSuccess: false,
            newTokenId: 0,
            progression: 0,
            tokenIndex: 0,
            collectionAmount: 100,
            nftDescription: "",
            saleIsActive: false,
            collectionProgressPercent: 100,
            collectionStatus: "Done"
        };

        this.mint = this.mint.bind(this);
        this.mintCallback = this.mintCallback.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.gotoToken = this.gotoToken.bind(this);
        this.unityCallback = this.unityCallback.bind(this);

    }

    async componentDidMount() {
        this.unityContext.on("DidUpload", this.unityCallback);
        this.unityContext.on("UnityGetStatus", this.unityStatusCallback);
        var collectionJson = await getJsonByIndex(this.collectionIndex);

        this.setState({
            nftDescription: collectionJson.nftDescription,
            collectionStatus: collectionJson.status
        });

        var collectionObject = await getCollection(this.collectionIndex);
        this.setState({
            tokenIndex: collectionObject.tokenIndex,
            collectionAmount: collectionObject.numberOfTokens,
            saleIsActive: collectionObject.isSaleActive,
            collectionProgressPercent: (collectionObject.tokenIndex / (collectionObject.numberOfTokens)) * 100
        }, () => {
            console.log("sale is active: " + this.state.saleIsActive)
            console.log("collectionProgressPercent:" + this.state.collectionProgressPercent);
        });

        //var mintedTokens = await getMintedTokens(this.collectionIndex)
        //this.setState({mintedTokens: mintedTokens}, () => {
        //    console.log(this.state.mintedTokens)
        //    var newIndex = Math.floor(Math.random() * this.state.collectionAmount);
        //    while (this.state.mintedTokens.includes(newIndex.toString())) {
        //        newIndex = Math.floor(Math.random() * this.state.collectionAmount);
        //    }
        //    console.log("new index " + newIndex);
        //});


    }

    async unityStatusCallback(status) {
        console.log("UNITY!! " + status);
    }

    async getRandomTokenIndex() {
        var newIndex = Math.floor(Math.random() * this.state.collectionAmount);
        while (newIndex === this.state.currentTokenIndex || this.state.mintedTokens.includes(newIndex.toString())) {
            newIndex = Math.floor(Math.random() * this.state.collectionAmount);
        }
    }


    async unityCallback(hash) {
        const response = await mintNFT(hash, this.collectionIndex, this.state.tokenIndex, this.mintCallback);
        if (response.mintStarted) {
            this.setState({
                mintResponse: "sending to blockchain...",
                mintSuccess: false
            });
        }
    }

    async mintCallback(tokenId) {
        console.log("minted new token with id " + tokenId);
        this.setState({
            mintResponse: "✅ token minted",
            mintSuccess: true,
            newTokenId: tokenId
        })
    }

    async mint(event) {
        var collectionObject = await getCollection(this.collectionIndex);
        await this.setState({tokenIndex: collectionObject.tokenIndex});
        console.log("amount: " + this.state.collectionAmount);

        var mintedTokens = await getMintedTokens(this.collectionIndex)
        var newIndex = Math.floor(Math.random() * this.state.collectionAmount);
        while (newIndex === this.state.currentTokenIndex || mintedTokens.includes(newIndex.toString())) {
            newIndex = Math.floor(Math.random() * this.state.collectionAmount);
        }
        console.log("new index " + newIndex);
        this.setState({tokenIndex: newIndex}, () => {
            var mintIndex = this.state.tokenIndex;
            var nftName = this.projectName + " #" + mintIndex;
            console.log("mint!!!  " + nftName);

            var nftDescription = this.state.nftDescription;
            this.unityContext.send("CompositionDataHandler", "SetName", nftName);
            this.unityContext.send("CompositionDataHandler", "SetDescription", nftDescription);
            this.unityContext.send("CompositionDataHandler", "CaptureNFT");
            this.setState({
                showModal: true,
                mintResponse: "Generating nft data...",
                mintSuccess: false
            });
        });


    }


    handleClose() {
        this.setState({showModal: false});
    }

    gotoToken() {
        window.location.href = "/view-token/" + this.state.newTokenId;
    }

    render() {
        const mintSuccess = this.state.mintSuccess;
        let footer;
        let header;

        if (mintSuccess) {
            footer = <Modal.Footer>
                <Button variant="primary" onClick={this.gotoToken}>Take me to it!</Button>
            </Modal.Footer>;
            header = <Modal.Title>mint done!</Modal.Title>;
        } else {
            footer = <Modal.Footer></Modal.Footer>;
            header = <Modal.Title><Spinner animation="border" size="sm"/> getting minted...</Modal.Title>;
        }

        const now = this.state.progression * 100;
        let progressInstance = <ProgressBar animated now={now} label={`${now}%`}/>;
        let loadingText = <p><Spinner animation="border" size="sm"/> loading collection</p>;
        let button;
        let warning;
        if (now === 100) {
            loadingText = "";
            progressInstance = "";
        }

        if (now === 100 && this.state.collectionProgressPercent < 100 && this.state.collectionStatus === "Active") {
            button = <Button variant="primary" onClick={this.mint}>Mint!</Button>;
            button = <MintButton onMint={this.mint}/>
            warning = <Badge bg="warning">Pressing mint will freeze the render in its current state</Badge>;
        }

        let unity = <Unity
            unityContext={this.unityContext}
            matchWebGLToCanvasSize={false}
            style={{
                width: "100%",
                height: "auto",
                background: "white",
            }}
        />;
        let viewToken = 10000;
        if(this.state.collectionStatus !== "Active") {
            unity = "";
            loadingText = "";
            progressInstance = "";
            viewToken = 10000 + Math.floor(Math.random() * 100);
        }
        
        //warning = <Badge bg="warning">Minting is paused for the moment</Badge>;
        return (
            <div>
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        {header}
                    </Modal.Header>
                    <Modal.Body>
                        {String(this.state.mintResponse)}
                    </Modal.Body>
                    {footer}
                </Modal>
                {loadingText}
                {progressInstance}
                
                {unity}
                <ViewToken id={viewToken}/>
                <br/>
                {button}
                <br/>
                {warning}

            </div>
        );
    }

}

export default CollectionUnity;