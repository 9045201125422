import React from "react";
import CollectionGallery from './CollectionGallery'
import {Col, Row, Container} from "react-bootstrap";

class Home extends React.Component {

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <h2>welcome to scramble.digital</h2>
                            <p>
                                A collective platform issuing artworks into the digital space.
                            </p>
                            <p>
                                Collections will be presented continuously by various artists working with both video,
                                graphics and raw natural elements. Stay tuned for our first mint session!
                            </p>
                            <p>
                                All mint fees go directly to the collection artist.
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <div className="section-header">
                            <span>active collections</span>
                        </div>
                    </Row>
                    <Row>
                        <CollectionGallery type={"Active"}/>
                        <CollectionGallery type={"Preview"}/>
                    </Row>
                    <Row>
                        <div className="section-header">
                            <span>upcoming collections</span>
                        </div>
                    </Row>
                    <Row>
                        <CollectionGallery small={true} type={"Upcoming"}/>
                    </Row>
                    <Row>
                        <div className="section-header">
                            <span>past collections</span>
                        </div>
                    </Row>
                    <Row>
                        <CollectionGallery small={true} type={"Done"}/>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Home;