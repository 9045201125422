import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {
    contractAddress,
    contractABI,
    web3
} from "./util/contract-controller-svg.js";



class ViewTokenSVG extends React.Component {

    constructor(props) {
        super(props);
        this.tokenId = this.props.match.params.id;
        this.state = {
            name: "...",
            description: "...",
            image: "...",
            properties: []
        }
        this.gotoOpensea = this.gotoOpensea.bind(this);

    }

    getToken = async (tokenId) => {
        console.log(tokenId)
        const contract = await new web3.eth.Contract(contractABI, contractAddress);
        return await contract.methods.tokenURI(tokenId).call();
    }

   
    async componentDidMount() {
        let token = await this.getToken(this.tokenId);
        await fetch(token)
            .then((response) => {
                return response.json()
            })
            .then((metadata) => {
                this.setState({
                    name: metadata.name,
                    description: metadata.description,
                    image: metadata.image,
                    properties: metadata.properties
                },() => {
                    //console.log(metadata.attributes)
                })
            }).catch(function () {
                console.log("error");
            });
        
    }

    gotoOpensea() {
        window.location.href = "https://opensea.io/assets/" + contractAddress + "/" + this.tokenId;
    }

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <img width="100%" alt="" src={this.state.image}/>
                            <p>
                                <h2>{String(this.state.name)}</h2>
                            </p>
                            <p>
                                <span className="text-muted">{String(this.state.description)}</span>
                            </p>
                            <Button onClick={this.gotoOpensea}>
                                view on opensea
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ViewTokenSVG;