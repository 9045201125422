require("dotenv").config();
const contractABI = require("../SCRMBLD.json").abi;
export const contractAddress = "0x755DC0246cb01Cd78478614839C8529B6C9798B4"; //main
//export const contractAddress = "0xfC80C183E7Fc2cE65F1dD4a927819f156DE2F552"; 
const Web3 = require('web3');
const web3 = new Web3('wss://eth-mainnet.alchemyapi.io/v2/OcKjwNiUlQGP4WWdIHiRTMqvgrJ2nhUl'); // main
//const web3 = new Web3('wss://eth-rinkeby.alchemyapi.io/v2/QMxhK-7GBjBzCKfux2ZqMxhHCj69C-Tw');


export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "",
                address: addressArray[0],
            };
            return obj;
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const getCollection = async (collectionIndex) => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    const collection = await contract.methods.getCollection(collectionIndex).call();
    //console.log(collection)
    if (collection[0] == null) {
        return null;
    }

    const collectionObject = {
        ipfs: web3.utils.hexToAscii(collection[0]),
        owner: collection[1],
        ownerCut: collection[2],
        priceInWei: collection[3],
        numberOfTokens: collection[4],
        tokenIndex: collection[5],
        isSaleActive: collection[6],
    };
    return collectionObject;
}

export const getChainIndexFromURI = async (uri) => {
    var data = await getJsonByURI(uri);
    return data.index;
}


export const getJsonByURI = async (uri) => {
    var jsonData = require('../json/CollectionsList.json');
    return jsonData.collections.find(data => data.uri === uri);
};

export const getJsonByIndex = async (index) => {
    var jsonData = require('../json/CollectionsList.json');
    return jsonData.collections.find(data => data.index === index);
};

export const getToken = async (tokenId) => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    const token = await contract.methods.tokenURI(tokenId).call();
    return token;
}

export const getMintedTokens = async (collectionIndex) => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    const token = await contract.methods.getAllTokenIndexes(collectionIndex).call();
    return token;
}


export const createCollection = async (ipfs, maxTokens, ownerAddress, ownerCut, mintPrice) => {
    console.log("create new collection");
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);


    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        data: window.contract.methods.createCollection(
            web3.utils.asciiToHex(ipfs),
            maxTokens,
            web3.utils.toChecksumAddress(ownerAddress),
            ownerCut,
            mintPrice)
            .encodeABI(),
    };

    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });


        return {
            mintStarted: true,
            status: "✅ Check out your transaction on Etherscan: https://rinkeby.etherscan.io/tx/" + txHash,
        };


    } catch (error) {
        return {
            mintStarted: false,
            status: "😥 Something went wrong: " + error.message,
        };
    }
};

export const getCreatedTokensList = async (mintCallback) => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    window.contract.events.newTokenMinted({
        filter: {},
        fromBlock: ""
    }, function (error, event) {
        mintCallback(event.returnValues[0]);
    })
};

export const mintNFT = async (hash, collectionIndex, mintIndex, mintCallback) => {
    console.log("mint collection: " + collectionIndex + " index " + mintIndex);
    const collection = await getCollection(collectionIndex);
    const mintPrice = web3.utils.fromWei(collection.priceInWei);

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    window.contract.events.newTokenMinted({
        filter: {},
        fromBlock: "latest"
    }, function (error, event) {
        console.log(error)
        mintCallback(event.returnValues[0]);
    })


    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        value: parseInt(web3.utils.toWei(mintPrice, 'ether')).toString(16),
        data: window.contract.methods.mintNFT(window.ethereum.selectedAddress,
            collectionIndex,
            mintIndex,
            hash).encodeABI(),
    };

    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });


        return {
            mintStarted: true,
            status: "✅ Check out your transaction on Etherscan: https://rinkeby.etherscan.io/tx/" + txHash,
        };


    } catch (error) {
        return {
            mintStarted: false,
            status: "😥 Something went wrong: " + error.message,
        };
    }
};

export const updateCollection = async (collectionIndex, ipfs, maxTokens, ownerAddress, ownerCut, mintPrice, isActive) => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);


    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        data: window.contract.methods.editCollection(
            collectionIndex,
            web3.utils.asciiToHex(ipfs),
            maxTokens,
            web3.utils.toChecksumAddress(ownerAddress),
            ownerCut,
            mintPrice,
            isActive
        ).encodeABI(),
    };

    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            success: true,
            status:
                "✅ Check out your transaction on Etherscan: https://rinkeby.etherscan.io/tx/" +
                txHash,
        };
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message,
        };
    }
};