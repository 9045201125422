export const contractABI = require("../../src/Contracts/Dazzles.json").abi;
const Web3 = require('web3');
//export const web3 = new Web3('wss://eth-rinkeby.alchemyapi.io/v2/QMxhK-7GBjBzCKfux2ZqMxhHCj69C-Tw'); // development
export const web3 = new Web3('wss://eth-mainnet.alchemyapi.io/v2/OcKjwNiUlQGP4WWdIHiRTMqvgrJ2nhUl'); // main

export const contractAddress = "0x9272a95103Cd6595DC6F9C63AdE34d398C82ff4C";


export const getContractData = async (collectionIndex) => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    var mintIsActive =  await contract.methods.mintIsActive().call()
    var mintPriceInWei = await contract.methods.mintPriceInWei().call();
    var maxTokens = await contract.methods.maximumTokens().call();
    var tokensMinted = await contract.methods.getTokensMinted().call();
    
    const collectionObject = {
        priceInWei: mintPriceInWei,
        numberOfTokens: maxTokens,
        tokenIndex: tokensMinted,
        isSaleActive: mintIsActive,
    };
    return collectionObject;
}

export const getContractTokens = async (collectionIndex) => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    var mintIsActive =  await contract.methods.mintIsActive().call()
    var mintPriceInWei = await contract.methods.mintPriceInWei().call();
    var maxTokens = await contract.methods.maximumTokens().call();
    var tokensMinted = await contract.methods.getTokensMinted().call();

    const collectionObject = {
        priceInWei: mintPriceInWei,
        numberOfTokens: maxTokens,
        tokenIndex: tokensMinted,
        isSaleActive: mintIsActive,
    };
    return collectionObject;
}



