import React from 'react';
import data from './json/CollectionsList.json';
import {Row, Card} from "react-bootstrap";

class CollectionGallery extends React.Component {

    constructor(props) {
        super(props);
        this.small = props.small;
        this.type = props.type;
        
        this.state = {
            collections: []
        }
    }

    componentDidMount() {

        if(this.type === "all") {
            var filteredCollections = data.collections.filter((d) => !d.status.includes("Hidden"));

            this.setState({
                collections: filteredCollections
            }); 
        } else {
            var activeCollections = data.collections.filter((d) => d.status.includes(this.type));
            this.setState({
                collections: activeCollections
            });
        }
        


    }

    clickCollection(index) {
        window.location.href = "/Collections/" + index;
    }


    render() {
        let cardWidth = 'auto';
        //let cardHeight = 'auto';
        
        if (this.small === true)
        {
            cardWidth = '22rem';
            //cardHeight = '160rem';
        }    

        return (
            <div>
                <Row>
                    {this.state.collections.map(dataRow => {
                        return (
                            <Card style={{width: cardWidth}} key={dataRow.index}
                                  onClick={() => this.clickCollection(dataRow.uri)}>
                                <div className="card-img-wrap">
                                    <Card.Img variant="top"  src={dataRow.coverImage}/>
                                </div>
                                <Card.Body>
                                    <Card.Title>
                                        <h4>
                                            {dataRow.name}
                                        </h4>
                                    </Card.Title>
                                    <Card.Text>
                                        <small> {dataRow.short_description}</small>
                                    </Card.Text>
                                    <Card.Text>
                                        <small> {dataRow.author}</small>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )
                    })}
                </Row>

            </div>
        );
    }
}

export default CollectionGallery;
