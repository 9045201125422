import React from "react";
import {Container} from "react-bootstrap";

class Footer extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="Footer">
                <Container fluid="sm">
                    © 2021 scramble
                    <small> · <a href="https://discord.gg/95a6mZctSM" target="_blank" rel="noreferrer">discord</a> · <a
                        href="https://twitter.com/scramble_dx" target="_blank" rel="noreferrer">twitter</a> ·  <a
                        href="https://etherscan.io/address/0x755dc0246cb01cd78478614839c8529b6c9798b4" target="_blank" rel="noreferrer">etherscan</a>
                    </small>
                </Container>
            </div>
        );
    }
}

export default Footer;