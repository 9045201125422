import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {
    connectWallet,
    getCurrentWalletConnected,
} from "./interact.js";

class MintButton extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            walletAddress: "",
            status: "",
            buttonState: this.props.state,
        }
        this.connectWalletPressed = this.connectWalletPressed.bind(this);
    }

    setWallet(walletString) {
        this.setState({walletAddress: walletString});
    }
    
    addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    this.setWallet(accounts[0]);
                } else {
                    this.setWallet("");
                }
            });
        } 
    }

    async componentDidMount() {
        const {address, status} = await getCurrentWalletConnected();
        this.setState({
            walletAddress: address,
            status: status
        });
        console.log("wallet address: " + address)
    }

    handleSubmit = () => {
        this.props.onMint();
    };

    handleNoWallet = () => {
        //window.location.href = "/about/";
    };

    async connectWalletPressed() {
        console.log("pressed button")
        const walletResponse = await connectWallet();
        this.setWallet(walletResponse.address);
    };

    render() {
        let button = "";
     if (this.state.buttonState === "inactive") {
            button = <Button variant="secondary" className="mint-button-inactive">composition is minted</Button>
        }
        else if (this.state.walletAddress.length > 0) {
            button =
                <Button variant="primary" className="mint-button" onClick={this.handleSubmit}>mint this!</Button>
        }  else {
            button = <Button variant="secondary" className="mint-button-inactive" onClick={this.connectWalletPressed}>connect
                wallet to mint</Button>
        }
        return (
            <div>
                <Row className="justify-content-md-center">
                    <Col xs lg="1"></Col>
                    <Col md="auto">
                        {button}
                    </Col>
                    <Col xs lg="1"></Col>
                </Row>
            </div>
        );
    }
}

export default MintButton;