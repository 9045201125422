import React from 'react';
import {
    createCollection
} from "./util/interact.js";
import {Container, Form, Row, Button, Col} from "react-bootstrap";

class CreateCollection extends React.Component {
    constructor(props) {
        super(props);
        this.collectionIndex = props.collectionIndex;
        this.state = {
            ipfs: '',
            ownerAddress: '0x0',
            ownerCut: 100,
            numberOfTokens: 0,
            mintProce: 0,
            isActive: '0',
            currentCollection: 1
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {

    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handleSubmit(event) {
        this.handleEditCollection();
        event.preventDefault();
    }


    async handleEditCollection() {
        await createCollection(
            this.state.ipfs,
            this.state.numberOfTokens,
            this.state.ownerAddress,
            this.state.ownerCut,
            this.state.mintPrice,
        );
    }

    render() {
        return (
            <div className="Form">
                <Container>
                    <Row>
                        <h3>Create Collection</h3>

                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group as={Row} className="sm-4">
                                <Form.Label column sm={3}>Ipfs:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" name="ipfs" onChange={this.handleInputChange}
                                                  placeholder="ipfs hash"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="sm-4">
                                <Form.Label column sm={3}>Owner address:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="text" name="ownerAddress" onChange={this.handleInputChange}
                                                  placeholder="0x0"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="sm-4">
                                <Form.Label column sm={3}>Owner cut:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="number" name="ownerCut" onChange={this.handleInputChange}
                                                  placeholder="100"/>
                                </Col>
                            </Form.Group>
                            
                            <Form.Group as={Row} className="sm-4">
                                <Form.Label column sm={3}>Number of tokens:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="number" name="numberOfTokens" onChange={this.handleInputChange}
                                                  placeholder="1000"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="sm-4">
                                <Form.Label column sm={3}>Mint price: (in Wei)</Form.Label>
                                <Col sm={8}>
                                    <Form.Control type="number" name="mintPrice" onChange={this.handleInputChange}
                                                  placeholder="10000000000000"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="sm-4" controlId="formHorizontalCheck">
                                <Col  sm={{span: 8, offset: 3}}>
                                    <Form.Check checked={this.state.isActive} name="isActive"
                                                onChange={this.handleInputChange} label="Sale active:"/>
                                </Col>
                            </Form.Group>
                            


                            <Form.Group as={Row} className="sm-4">
                                <Col sm={{span: 8, offset: 3}}>
                                    <Button className="mint-button"
                                            onClick={this.handleSubmit}>create new collection</Button>
                                </Col>
                            </Form.Group>

                        </Form>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default CreateCollection;