import React from 'react';
import {Row, Card, Container} from "react-bootstrap";
import {
    contractABI,
    web3,
    contractAddress
} from "./util/contract-controller-svg.js";

class CollectionSVGGallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tokens: []
        }
    }

    async componentDidMount() {
        var maxToken = await this.getMaxTokenId();
        var count = 0;
        for (var i = maxToken-1; i >= 0; i--) {
            await this.fetchAndSetToken(i); 
            count++;
            if(count > 12) break;
        }
        

    }

    async fetchAndSetToken(tokenIndex) {
        var contract = await new web3.eth.Contract(contractABI, contractAddress);
        var token = await contract.methods.tokenURI(tokenIndex).call();
        await fetch(token)
            .then((response) => {
                return response.json()
            })
            .then((metadata) => {
                this.setState({
                    tokens: [...this.state.tokens, {
                        name: metadata.name,
                        image: metadata.image,
                        tokenId: tokenIndex
                    },]
                });

            }).catch(function () {
                console.log("error");
            });
    }

    async getToken() {

    }

    async getMaxTokenId() {
        var contract = await new web3.eth.Contract(contractABI, contractAddress);
        var maxToken = await contract.methods.getTokensMinted().call();
        return maxToken;
    }


    clickToken(index) {
        window.location.href = "/view-token-svg/" + index;
    }


    render() {


        return (
            <div>
                <Container>
                    <Row>

                        {this.state.tokens.map(dataRow => {
                            return (

                                <Card style={{width: '10rem'}} key={dataRow.name}
                                      onClick={() => this.clickToken(dataRow.tokenId)}>
                                    <div className="card-img-wrap">
                                        <Card.Img variant="top" src={dataRow.image}/>
                                    </div>
                                    <Card.Body>
                                        <Card.Text><small>{dataRow.name}</small></Card.Text>
                                    </Card.Body>
                                </Card>

                            )
                        })}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default CollectionSVGGallery;
