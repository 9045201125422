import React from "react";
import {Col, Container, Row} from "react-bootstrap";

class About extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Container>

                    <Row>
                        <h2>about scramble.digital</h2>
                        <Col md lg="8">
                            <p>
                                Welcome to a collective platform issuing artworks into the digital space.
                            </p>
                            <p>
                                Collections will be presented continuously by various artists working with both video,
                                graphics and raw natural elements. Stay tuned for our first mint session!
                            </p>
                            <p>
                                All mint fees go directly to the collection artist.
                            </p>
                            <p>
                                ENJOY THE RIDE!
                            </p>
                        </Col>

                    </Row>
                    <Row>

                        <h2>how to mint</h2>
                        <Col md lg="8">
                            
                            <p>
                                In order to use this site you must have Metamask installed 🦊
                            
                                If you dont have Metamask installed you can <a target="_blank" rel="noreferrer"
                                                                               href={`https://metamask.io/download.html`}>download
                                it here</a>
                            </p>
                            <p>
                                Connect to Metamask using the top right button.
                            </p>

                            <p>
                                Navigate to a collection you like and randomize it until you like the result. Press the
                                'mint this' button below the token preview. The page will start to generate the token
                                and after a short while Metamask will pop up asking you to confirm the
                                transaction.
                            </p>

                        </Col>
                    </Row>

                </Container>
            </div>
        );
    }
}

export default About;